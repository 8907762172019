<template>
  <div class="table-container">
    <!-- TODO: Add various columns from VOICE -->
    <el-table
      :data="tableData.data"
      v-loading="tableLoading"
      height="50vh"
      :show-overflow-tooltip="true"
    >
      <el-table-column
        width="120"
        label="Number"
        prop="number"
      ></el-table-column>
      <el-table-column
        width="170"
        label="Campaign Name"
        prop="campaign_name"
      ></el-table-column>

      <el-table-column prop="status" label="Dial Status" width="120">
        <template slot-scope="scope" class="contains-status">
          <p
            v-if="scope.row.dial_status.toUpperCase() == 'ANSWERED'"
            style="color: #36d962; margin: 0 auto"
          >
            ANSWERED
          </p>
          <p
            v-else-if="scope.row.dial_status.toUpperCase() == 'NO ANSWER'"
            style="color: #000; margin: 0 auto"
          >
            NO ANSWER
          </p>
          <p
            v-else-if="scope.row.dial_status.toUpperCase() == 'PENDING'"
            style="color: #30a1f0; margin: 0 auto"
          >
            PENDING
          </p>
          <p
            v-else-if="scope.row.dial_status.toUpperCase() == 'BUSY'"
            style="color: #ff0000; margin: 0 auto"
          >
            BUSY
          </p>
          <p
            v-else-if="scope.row.dial_status.toUpperCase() == 'EXPIRED'"
            style="color: #f7921c; margin: 0 auto"
          >
            EXPIRED
          </p>
          <p
            v-else-if="scope.row.dial_status.toUpperCase() == 'not_dialed_yet'"
            style="color: #30a1f0; margin: 0 auto"
          >
            NOT DIALED YET
          </p>
        </template>
      </el-table-column>
      <el-table-column
        label="Answer Time"
        prop="answer_time"
        width="180"
      ></el-table-column>
      <el-table-column
        label="Hang Up Time"
        prop="hang_up_time"
        width="180"
      ></el-table-column>
      <el-table-column
        label="Duration"
        prop="duration"
        width="120"
      ></el-table-column>
      <el-table-column
        label="Retries"
        prop="retries"
        width="120"
      ></el-table-column>
      <el-table-column label="Refund" prop="refund" width="120">
        <template slot-scope="scope">
          {{ scope.row.refund == 1 ? "Refunded" : "Pending" }}
        </template>
      </el-table-column>
      <el-table-column
        label="Date & Time"
        prop="date_time"
        width="170"
      ></el-table-column>
    </el-table>
    <div class="col-md-12" style="text-align: center; margin-top: 2em">
      <el-pagination
        v-if="tableData.data"
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :page-size="tableData.per_page || 0"
        :total="tableData.total || 0"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      tableLoading: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      handleCurrentChange(val) {
        this.$emit("updatePage", val);
      },
    },
    computed: {
      tableData() {
        return this.$store.state.admin.reseller.resellerReportsVoice;
      },
    },
    mounted() {
      this.$store.dispatch("admin/reseller/getResellerDomainNames");
      this.$store.dispatch("admin/reseller/clearResellerReportsVoice")
    },
  };
</script>

<style scoped>
  .table-container {
    width: 100%;
    overflow-x: auto;
  }
</style>
