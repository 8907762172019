<template>
  <div>
    <div class="top-section">
      <div class="block">
        <el-date-picker
          v-model="date_item"
          type="daterange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
        >
        </el-date-picker>
      </div>
      <div class="block">
        <el-select v-model="formData.domain" placeholder="Domain" filterable>
          <el-option
            v-for="item in domains"
            :key="item.id"
            :label="item.domain"
            :value="item.domain"
          ></el-option>
        </el-select>
      </div>
      <div class="block">
        <el-button
          type="primary"
          :loading="search_loading"
          @click="searchResellerHistory"
          >Search</el-button
        >
      </div>
    </div>
    <div class="contains-chart">
      <reportsTable
        :tableLoading="tableLoading"
        @updatePage="handleUpdatePage($event)"
      ></reportsTable>
      <voiceReportsChart></voiceReportsChart>
    </div>
  </div>
</template>

<script>
  import reportsTable from "./reportsTable.vue";
  import voiceReportsChart from "./voiceReportsChart.vue";
  export default {
    components: {
      reportsTable,
      voiceReportsChart,
    },
    data() {
      return {
        formData: {
          from: "",
          to: "",
          domain: null,
        },
        date_item: [],
        search_loading: false,
        tableLoading: false,
      };
    },
    computed: {
      domains() {
        return this.$store.state.admin.reseller.resellerDomainNames;
      },
    },
    methods: {
      searchResellerHistory() {
        // check form validation
        if (!this.date_item.length) {
          this.$message.error("Please select date range.");
          return;
        }
        if (!this.formData.domain) {
          this.$message.error("Please select domain.");
          return;
        }
        this.search_loading = true;
        this.tableLoading = true;
        this.formData.from = this.date_item[0]
          .toISOString()
          .replace(/T/, " ")
          .replace(/\..+/, "")
          .split(" ")[0];
        this.formData.to = this.date_item[1]
          .toISOString()
          .replace(/T/, " ")
          .replace(/\..+/, "")
          .split(" ")[0];
        this.$store
          .dispatch("admin/reseller/getResellerVoiceReports", this.formData)
          .then(() => {
            this.search_loading = false;
          })
          .catch(() => {
            this.$message.error(
              "Something went wrong, please try again later."
            );
          })
          .finally(() => {
            this.search_loading = false;
            this.tableLoading = false;
          });
      },
      handleUpdatePage(val) {
        this.tableLoading = true;
        this.loading = true;
        this.$store
          .dispatch("admin/reseller/getResellerVoiceReports", {
            ...this.formData,
            page: val,
          })
          .catch(() => {
            this.$message.error(
              "Something went wrong, please try again later."
            );
          })
          .finally(() => {
            this.tableLoading = false;
            this.loading = false;
          });
      },
    },
  };
</script>

<style scoped>
  .top-section {
    display: flex;
    justify-content: space-evenly;
    margin-block: 2rem;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .block >>> .el-range-separator {
    margin-inline: 1rem !important;
  }

  .contains-chart {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    width: 100%;
  }
</style>
